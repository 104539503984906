<template>
    <onboarding-element>
        <span slot="heading">Log in to your account</span>
        <span slot="instruction"
            >Enter your email address and password<br />
            to access your dashboard.
        </span>
        <div slot="input-fields" class="relative">
            <input
                type="email"
                placeholder="Email addresss"
                class="w-full border border-color rounded p-3 my-3 focus:border-primary-color-orange focus:outline-none"
            />
            <input
                :type="passwordType"
                placeholder="Enter password"
                class="w-full border border-color rounded p-3 my-3 focus:border-primary-color-orange focus:outline-none"
            />
            <div class="absolute right-6 bottom-6">
                <img
                    v-if="passwordType === 'password'"
                    src="@/assets/images/eye-icon-disabled.svg"
                    alt="enter password"
                    @click="passwordType = 'text'"
                    class="w-6 h-6"
                />
                <img
                    v-if="passwordType === 'text'"
                    src="@/assets/images/eye-icon-enabled.svg"
                    alt="enter password"
                    @click="passwordType = 'password'"
                    class="w-6 h-6"
                />
            </div>
        </div>
        <div slot="forgot" class="text-right text-sm focus:outline-none">Forgot Password?</div>
        <span slot="button-text">Sign in</span>
    </onboarding-element>
</template>

<script>
import OnboardingElement from '@/components/Onboarding.vue'

export default {
    name: 'Login',
    components: { OnboardingElement },
    data() {
        return {
            passwordType: 'password',
        }
    },
}
</script>
